.loading-bar-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999;
}

.loading-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: inherit;
  background-color: #4D7CFE;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 999999;
}

.loading-bar-full {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: inherit;
  transition: opacity 0.1s ease-out;
  z-index: 999999;
}
